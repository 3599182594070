<template>
  <div>
    <section class="home-banner">
      <img src="~/assets/images/banner.jpg" alt="">
    </section>
    <section class="home-section-board  m-5">
      <div class="text-center my-5">
        <h1 class="">Online Job Application System (Employer)</h1>
      </div>

      <div class="row my-5" v-if="!authenticated">
        <div class="col-md-6 offset-md-3">
          <HomeLogin />
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import HomeLogin from '~/components/auth/HomeLogin';
export default {
  layout: 'main',
  components:{ HomeLogin },
  data(){
    return {
      someImg: ''
    }
  },
  methods:{
  },
  head() {
    return {
      title: 'Home | SteveJobs',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'SteveJobs.com.au is a job searching and employer hosting platform.'
        }
      ]
    }
  }
}
</script>

<style scoped>
.home-section-board{
  min-height: 60vh;
}
.home-banner img {
    width: 100%;
    height: auto;
}

</style>
